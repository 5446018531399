import React from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledHeader,
  StyledHeaderNav,
  StyledHeaderNews,
  StyledLastNews,
  StyledLastNewsTitle,
  StyledPreviousNews,
  StyledNextNews,
} from "./styles";
import IconSelector from "../../ui/IconSelector";

import shevron from "../../assets/shevron.webp";

import HeaderInfo from "./HeaderInfo/HeaderInfo";
import LogoContainer from "./LogoContainer/LogoContainer";

const Header = () => {
  const navigate = useNavigate();

  const handleHeaderNav = (event) => {
    const id = event.target.id;
    navigate(id);
  };

  return (
    <StyledHeader>
      <HeaderInfo />
      <LogoContainer />
      <StyledHeaderNav>
        <ul>
          <li>
            <div onClick={handleHeaderNav} id="/">
              Baş sahypa
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/habarlar">
              Habarlar
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/maglumatlar">
              Maglumatlar
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/multimediýa">
              Multimediýa
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/salgylar">
              Salgylar
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/tölegler">
              Onlaýn tölegler
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/şahsy-otag">
              <IconSelector id="user" />
              <p>Şahsy otag</p>
            </div>
          </li>
        </ul>
      </StyledHeaderNav>
      <StyledHeaderNews>
        <StyledLastNews>
          <p>Soňky habarlar:</p>
          <p>31-05-2023</p>
        </StyledLastNews>
        <StyledLastNewsTitle>
          <img src={shevron} alt="shevron" />
          <p>Merkezi aziýa nobatdaky hyzmatdaşlygy</p>
        </StyledLastNewsTitle>
        <StyledPreviousNews>
          <IconSelector id="headerPrevius" />
        </StyledPreviousNews>
        <StyledNextNews>
          <IconSelector id="headerNext" />
        </StyledNextNews>
      </StyledHeaderNews>
    </StyledHeader>
  );
};

export default Header;
