import styled from "styled-components";
import { media } from "../../ui/media";

const StyledHeader = styled.div`
  width: 100%;
`;

const StyledHeaderNav = styled.nav`
  height: 70px;
  background: #2c5d5b;
  padding: 0 150px;
  div {
    color: #ffffff;
  }
  ul {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: DM Sans;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    li {
      cursor: pointer;
    }
    li:last-child {
      div {
        position: relative;
        width: 140px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: #e8fffe;
        font-size: 14px;
        line-height: 18px;
        color: #2c5d5b;
      }

      svg {
        margin-right: 11px;
      }
    }
  }
  ${media.TABLET} {
    padding: 0 20px;
    ul {
      font-size: 15px;
      line-height: 20px;
      li:last-child {
        width: max-content;
        height: 40px;
        padding: 5px;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  ${media.PHONE} {
    display: none;
  }
`;

const StyledHeaderNews = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 150px;
  ${media.TABLET} {
    padding: 0 20px;
    justify-content: center;
  }
  ${media.PHONE} {
    background: #2c5d5b;
  }
`;

const StyledLastNews = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: #000000;
  border-right: 1px solid #d9d9d9;
  p {
    font-family: DM Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  p:last-child {
    margin: 0 45px;
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;
const StyledLastNewsTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 45px;
  cursor: pointer;
  p {
    font-family: DM Sans;
    font-weight: 500;
    font-size: 18px;
    line-height: 23.44px;
    background: -webkit-linear-gradient(#bbbbbb, #000000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  img {
    width: 25px;
    height: 29px;
    margin: 0 30px;
  }
  ${media.TABLET} {
    margin-left: 0px;
  }
  ${media.PHONE} {
    display: flex;
    align-items: space-between;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18.44px;
      text-align: center;
      background: -webkit-linear-gradient(rgb(248, 183, 5), rgb(255, 255, 255));
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    img {
      display: none;
    }
  }
`;

const StyledPreviousNews = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 41px;
  margin-left: 42px;
  margin-right: 16px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  cursor: pointer;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    height: 31px;
    width: 31px;
  }
`;
const StyledNextNews = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 41px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  cursor: pointer;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    height: 31px;
    width: 31px;
  }
`;

export {
  StyledHeader,
  StyledHeaderNav,
  StyledHeaderNews,
  StyledLastNews,
  StyledLastNewsTitle,
  StyledPreviousNews,
  StyledNextNews,
};
