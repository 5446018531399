import styled from "styled-components";
import { media } from "../../../ui/media";

const StyledLogoContainer = styled.div`
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  @media (max-width: 1300px) {
    padding: 0 50px;
  }
  ${media.TABLET} {
    padding: 0 20px;
  }
  ${media.PHONE} {
    height: max-content;
  }
`;

const StyledHeaderLogo = styled.div`
  cursor: pointer;
  img {
    width: 255px;
    height: 71px;
  }
  ${media.TABLET} {
    margin: 0 auto;
  }
  ${media.PHONE} {
    img {
      width: 255px;
      height: 71px;
    }
  }
`;
const StyledSearchInput = styled.div`
  position: relative;
  input {
    width: 366px;
    height: 38px;
    padding: 10px;
    padding-right: 30px;
    border: 1px solid #cdcdcd;
    border-radius: 20px;
    text-align: center;
  }
  svg {
    top: 32%;
    right: 12px;
    position: absolute;
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
    input {
      display: none;
    }
    svg {
      display: none;
    }
  }
`;
const StyledYearLogo = styled.div`
  display: flex;
  align-items: center;
  p {
    width: 260px;
    font-family: DM Sans;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background: -webkit-linear-gradient(#00403e, #00827c);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img {
    width: 89px;
    height: 88px;
  }
  @media (max-width: 1080px) {
    display: none;
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
    display: none;
  }
`;
export {
  StyledLogoContainer,
  StyledHeaderLogo,
  StyledSearchInput,
  StyledYearLogo,
};
