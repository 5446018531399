import styled from "styled-components";
import { media } from "../../../ui/media";

const StyledHeaderInfo = styled.div`
  height: 52px;
  background: #2c5d5b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  p {
    font-family: DM Sans;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
  svg {
    margin-right: 10px;
  }

  ${media.LAPTOP} {
    padding: 0 50px;
  }
  ${media.TABLET} {
    padding: 0 50px;
    position: relative;
    width: 100%;

    svg {
      margin-right: 5px;
    }
  }
  ${media.PHONE} {
    padding: 0 20px;
  }
`;

const StyledInfo = styled.div`
  height: 37px;
  display: flex;
  ${media.TABLET} {
    justify-content: start;
  }
`;

const StyledClock = styled.div`
  display: flex;
  align-items: center;
  padding-right: 25px;
  ${media.TABLET} {
    width: max-content;
    padding-right: 15px;
  }
  ${media.PHONE} {
    display: none;
  }
`;

const StyledCalendar = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid #417e7c;
  border-right: 1px solid #417e7c;
  padding: 0 18px;
  ${media.TABLET} {
    width: max-content;
    padding: 0 15px;
  }
  ${media.PHONE} {
    border: none;
    padding-left: none;
  }
`;

const StyledPhone = styled.div`
  display: flex;
  align-items: center;
  padding-left: 25px;
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
    display: none;
  }
`;

const StyledLanguageContainer = styled.div`
  position: relative;

  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
    display: none;
  }
`;

const StyledLanguage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    margin-left: 10px;
    margin-right: 5px;
  }
  svg {
    margin-right: 0;
  }
  ${media.TABLET} {
    justify-content: start;
    margin-left: 15px;
    p {
      display: none;
    }
    svg {
      margin-left: 15px;
    }
  }
`;

const StyledLanguageDropdown = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: absolute;
  width: 100%;
  top: 37px;
  background-color: #fff;
  ${media.LAPTOP} {
    z-index: 1;
  }
  ${media.TABLET} {
    width: max-content;
    right: 0;
  }
`;

const StyledLanguageDropdownItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #417e7c;
  &:first-child {
    border-top: 1px solid #417e7c;
  }
  cursor: pointer;
  p {
    color: black;
  }
  &:hover {
    background-color: #417e7c;
    p {
      color: white;
    }
  }
  ${media.TABLET} {
    width: 60px;
    display: flex;
    justify-content: center;
    padding: 15px;
    background-color: #00000050;
    z-index: 2;
    p {
      display: none;
    }
  }
`;

const StyledFlag = styled.img`
  width: 30px;
  height: 20px;
`;
const StyledMenuBar = styled.div`
  display: none;

  ${media.TABLET} {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-left: 15px;

    cursor: pointer;
    svg {
      width: 22px;
    }
  }
  ${media.PHONE} {
    display: flex;
    justify-content: right;
    align-items: center;

    cursor: pointer;
    svg {
      width: 22px;
    }
  }
`;

export {
  StyledHeaderInfo,
  StyledInfo,
  StyledClock,
  StyledCalendar,
  StyledPhone,
  StyledLanguageContainer,
  StyledLanguage,
  StyledLanguageDropdown,
  StyledLanguageDropdownItem,
  StyledFlag,
  StyledMenuBar,
};
