import React, { useEffect, useState } from "react";
import {
  StyledMenuBar,
  StyledHeaderInfo,
  StyledInfo,
  StyledClock,
  StyledCalendar,
  StyledPhone,
  StyledLanguageContainer,
  StyledLanguage,
  StyledLanguageDropdown,
  StyledLanguageDropdownItem,
  StyledFlag,
} from "./styles";
import IconSelector from "../../../ui/IconSelector";
import {
  getLanguage,
  getLanguageDropdown,
  getLanguageOptions,
} from "../../../store/selectors/languageSelectors";
import {
  changeLanguage,
  closeLanguageDropDown,
  openLanguageDropDown,
} from "../../../store/slices/languageSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { openMenu } from "../../../store/slices/burgerMenuSlice";
const HeaderInfo = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const languageDropDown = useAppSelector(getLanguageDropdown);
  const languageOptions = useAppSelector(getLanguageOptions);
  const dispatch = useAppDispatch();
  const language = useAppSelector(getLanguage);
  const handleBurgerMenu = () => {
    dispatch(openMenu());
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const daysInTurkmen = {
    Monday: "Duşenbe",
    Tuesday: "Sişenbe",
    Wednesday: "Çarşenbe",
    Thursday: "Penşenbe",
    Friday: "Anna",
    Saturday: "Şenbe",
    Sunday: "Ýekşenbe",
  };
  const dayOfWeek = currentTime.toLocaleDateString("en-US", {
    weekday: "long",
  });

  const turkmenDayOfWeek = daysInTurkmen[dayOfWeek] || dayOfWeek;

  const handleLanguageDropdown = () => {
    if (languageDropDown) {
      dispatch(closeLanguageDropDown());
    } else {
      dispatch(openLanguageDropDown());
    }
  };
  const handleLanguage = (e) => {
    dispatch(changeLanguage(e.currentTarget.id));
    dispatch(closeLanguageDropDown());
  };
  return (
    <StyledHeaderInfo>
      <StyledInfo>
        <StyledClock>
          <IconSelector id="clock" />
          <p>
            {currentTime.getHours()}:
            {String(currentTime.getMinutes()).padStart(2, "0")}
          </p>
        </StyledClock>
        <StyledCalendar>
          <IconSelector id="calendar" />
          <p>
            {currentTime
              .toLocaleDateString("tr-TR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\./g, "-")}
            , {turkmenDayOfWeek}
          </p>
        </StyledCalendar>
        <StyledPhone>
          <IconSelector id="phone" />
          <p>+99364929340</p>
        </StyledPhone>
      </StyledInfo>
      <StyledMenuBar onClick={handleBurgerMenu}>
        <IconSelector id="burgermenu" />
      </StyledMenuBar>
      <StyledLanguageContainer>
        <StyledLanguage onClick={handleLanguageDropdown}>
          <StyledFlag
            src={languageOptions.find((option) => option.id === language)?.flag}
            alt="flag"
          />
          <p>Dil saýlaň:</p>
          <IconSelector id="dropdown" />
        </StyledLanguage>
        <StyledLanguageDropdown open={languageDropDown}>
          {languageOptions.map((option) => (
            <StyledLanguageDropdownItem
              key={option.id}
              id={option.id}
              href="#"
              onClick={handleLanguage}
            >
              <p>{option.name}</p>
              <StyledFlag src={option.flag} alt={option.name} />
            </StyledLanguageDropdownItem>
          ))}
        </StyledLanguageDropdown>
      </StyledLanguageContainer>
    </StyledHeaderInfo>
  );
};

export default HeaderInfo;
