import { Breakpoint } from "./breakpoints";

export const media = {
  PHONE: `@media (max-width: ${Breakpoint.SM}px)`, // SM size kadar olan aralık (0px - 415px)
  TABLET: `@media (min-width: ${Breakpoint.SM + 1}px) and (max-width: ${
    Breakpoint.MD
  }px)`, // 415px'den büyük, 768px'e kadar olan aralık
  LAPTOP: `@media (min-width: ${Breakpoint.MD + 1}px) and (max-width: ${
    Breakpoint.XL
  }px)`, // 768px'den büyük, 1920px'e kadar olan aralık
  DESKTOP: `@media (min-width: ${Breakpoint.XL + 1}px)`, // XL size'dan büyük aralık (1920px'den büyük)
};
