import React from "react";
import {
  StyledLogoContainer,
  StyledHeaderLogo,
  StyledSearchInput,
  StyledYearLogo,
} from "./styles";
import tiimlogo from "../../../assets/tiimlogo.webp";
import magtymguly from "../../../assets/magtymguly.webp";
import IconSelector from "../../../ui/IconSelector";
import { useAppSelector } from "../../../store/hooks/hooks";

import { getLanguage } from "../../../store/selectors/languageSelectors";
import { useNavigate } from "react-router-dom";

const LogoContainer = () => {
  const navigate = useNavigate();
  const language = useAppSelector(getLanguage);

  const handleHeaderLogo = () => {
    navigate(`/`);
  };
  return (
    <StyledLogoContainer>
      <StyledHeaderLogo onClick={handleHeaderLogo}>
        <img src={tiimlogo} alt="tiimlogo" />
      </StyledHeaderLogo>
      <StyledSearchInput>
        <input type="text" />
        <IconSelector id="search" />
      </StyledSearchInput>
      <StyledYearLogo>
        {language === "en" ? (
          <p>Year 2024 - «Magtymguly Fraghi's fount of Mind»</p>
        ) : language === "ru" ? (
          <p>2024 год - «Кладезь разума Махтумкули Фраги»</p>
        ) : (
          <p>2024-nji ýyl - «Pähim-paýhas ummany Magtymguly Pyragy» ýyly</p>
        )}

        <img src={magtymguly} alt="Pähim-paýhas ummany Magtymguly Pyragy" />
      </StyledYearLogo>
    </StyledLogoContainer>
  );
};

export default LogoContainer;
